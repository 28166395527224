import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { PageHeader } from '../components/PageHeader'
import { Container } from '../components/Container'
import {
    useFeeScheduleByGroupId,
    getFeeScheduleByIdList
} from '../service/feeScheduleService'
import {
    describeFeeStructureTierFormat,
    renderDateFromIsoString
} from '../utils/Formatters'
import { Table } from '@interstate/components/Table'
import { Tooltip } from '../components/ToolTip'
import { RateCardOnTooltip } from '../components/ratecard/RateCardOnTooltip'
import { InfoIcon } from '../components/Icons'
import { PatSuccessAlert } from '../components/common/PatSuccessAlert'
import { DisplayFeeTierAmount } from '../components/search/DisplayFeeTierAmount'
import { DisplayFlatTieredDescription } from '../components/search/DisplayFlatTieredDescription'
import { deleteStorage, getStorage, setStorage } from '../utils/storageService'
import {
    FEE_SCHEDULE_STORAGE_KEY,
    PUBLISHED_FEE_SCHEDULE_STORAGE_KEY
} from '../utils/constants'
import { ConfirmationPageTable } from '../components/review/ConfirmationPageTable'

export function EditFeeScheduleConfirmation(props) {
    let [searchParams] = useSearchParams()
    const feeScheduleGroupId = searchParams.get('fsg')
    const [createdFeeScheduleFlatList, setCreatedFeeScheduleFlatList] =
        useState([])
    const [expiredFeeScheduleFlatList, setExpiredFeeScheduleFlatList] =
        useState([])
    const [deletedFeeScheduleFlatList, setDeletedFeeScheduleFlatList] =
        useState([])

    const {
        value: feeScheduleFlatList,
        loading: feeScheduleFlatListLoading,
        error: feeScheduleFlatListError
    } = useFeeScheduleByGroupId(feeScheduleGroupId)

    useEffect(() => {
        if (feeScheduleFlatList) {
            setCreatedFeeScheduleFlatList(feeScheduleFlatList)

            // get the expired and deleted ids from the storage
            let publishedInfoString = getStorage(
                PUBLISHED_FEE_SCHEDULE_STORAGE_KEY
            )
            let publishedInfo = JSON.parse(publishedInfoString)
            if (feeScheduleGroupId === publishedInfo?.feeScheduleGroup) {
                if (
                    publishedInfo.feeScheduleExpiredDuringPublish &&
                    publishedInfo.feeScheduleExpiredDuringPublish.length > 0
                ) {
                    getFeeScheduleByIdList(
                        publishedInfo.feeScheduleExpiredDuringPublish
                    ).then((expiredList) => {
                        setExpiredFeeScheduleFlatList(expiredList)
                    })
                }

                if (
                    publishedInfo.feeScheduleDeletedDuringPublish &&
                    publishedInfo.feeScheduleDeletedDuringPublish.length > 0
                ) {
                    getFeeScheduleByIdList(
                        publishedInfo.feeScheduleDeletedDuringPublish
                    ).then((deletedList) => {
                        setDeletedFeeScheduleFlatList(deletedList)
                    })
                }
            }
        }
    }, [feeScheduleFlatList])

    return (
        <Container>
            <PageHeader titleValue="Fee Schedule Confirmation" />

            <PatSuccessAlert
                success={true}
                text="The following fee schedules were created"
                disableFade={false}
            />
            <div>&nbsp;</div>
            <div>New Fee Schedules Added</div>
            {!feeScheduleFlatListError && !feeScheduleFlatListLoading && (
                <div>
                    <ConfirmationPageTable
                        tableData={createdFeeScheduleFlatList}
                    />
                </div>
            )}

            {/*Table to display overlapping fees that was expired*/}
            <div>&nbsp;</div>
            <div>Expired Fee Schedules</div>
            {expiredFeeScheduleFlatList && (
                <div>
                    <ConfirmationPageTable
                        tableData={expiredFeeScheduleFlatList}
                    />
                </div>
            )}

            {/*Table to display overlapping fees that was deleted*/}
            <div>&nbsp;</div>
            <div>Deleted Fee Schedules</div>
            {deletedFeeScheduleFlatList && (
                <div>
                    <ConfirmationPageTable
                        tableData={deletedFeeScheduleFlatList}
                    />
                </div>
            )}
        </Container>
    )
}
