import React from 'react'
import { dollarsFull } from '../../utils/Formatters'
import { RateCardOnTooltip } from '../ratecard/RateCardOnTooltip'
import { InfoIcon } from '../Icons'
import { Tooltip } from '../ToolTip'

export function DisplayFeeTierAmount({ feeScheduleFlat, saleTypeFee }) {
    let feeStructure = null
    if (feeScheduleFlat) {
        feeStructure = feeScheduleFlat.feeStructure
    } else if (saleTypeFee) {
        feeStructure = saleTypeFee.feeStructure
    }

    function displayFeeAmount() {
        if (feeStructure && feeStructure.feeTiers) {
            let tierCount = feeStructure.feeTiers.length
            if (tierCount === 1) {
                return (
                    <div>
                        <span>
                            {dollarsFull.format(feeStructure.feeTiers[0].fee)}
                        </span>
                    </div>
                )
            } else if (tierCount > 1) {
                return (
                    <Tooltip
                        content={
                            <RateCardOnTooltip feeStructure={feeStructure} />
                        }
                        direction="bottom">
                        <span>
                            {tierCount} Tiers &nbsp;
                            <InfoIcon />
                        </span>{' '}
                        &nbsp;
                    </Tooltip>
                )
            }
        }
        return null
    }

    return <>{displayFeeAmount()}</>
}
