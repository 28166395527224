import React from 'react'
import { Tooltip } from '../ToolTip'
import { RateCardOnTooltip } from '../ratecard/RateCardOnTooltip'
import { FEE_FLAT_TIERED } from '../../resources/enum.constants'
import { InfoIcon } from '../Icons'
import { dollarsFull } from '../../utils/Formatters'

export function DisplayFlatTieredDescription({ feeScheduleFlat, saleTypeFee }) {
    let feeStructure = null
    if (feeScheduleFlat) {
        feeStructure = feeScheduleFlat.feeStructure
    } else if (saleTypeFee) {
        feeStructure = saleTypeFee.feeStructure
    }

    function displayFlatTiered() {
        if (feeStructure && feeStructure.feeTiers) {
            let tierCount = feeStructure.feeTiers.length
            if (tierCount === 1) {
                return <div>{FEE_FLAT_TIERED.FLAT}</div>
            } else if (tierCount > 1) {
                return <div>{FEE_FLAT_TIERED.TIERED}</div>
            }
        }
        return null
    }

    return <>{displayFlatTiered()}</>
}
