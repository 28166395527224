import { React, useState } from 'react'
import { isNumeric } from '../../utils/commonValidations'
import {
    populateDollarPercentOptions,
    validateCreateRateCard
} from '../../utils/rate.card.helper'
import { saveRateCardData } from '../../service/rateCardService'
import { SelectInput } from '@interstate/components/SelectInput'
import { SubmitCancelBox } from '../common/SubmitCancelBox'
import { Alert } from '@interstate/components/Alert'
import { Grid } from '@interstate/components/Grid'

import { Box } from '@interstate/components/Box'

import { TextInput } from '@interstate/components/TextInput'

export function CreateRateCard({
    onRateCardSelection,
    feeOverrideIndex,
    toggleRateCardCreateModal,
    moduleid
}) {
    const defaultValues = {
        rateCardDescription: '',
        addFee: '',
        addFeeType: 'AMT',
        addFeeForEveryAmount: '',
        addFeeAfterAmount: '',
        addFeeMaxAmount: ''
    }
    const dollarPercentOptions = populateDollarPercentOptions()
    const [priceTierList, setPriceTierList] = useState([])
    const [rateCardInfo, setRateCardInfo] = useState(defaultValues)
    const [errorMessages, setErrorMessages] = useState()

    function priceTierCountChange(event) {
        let priceTierCount = event.target.value
        if (isNumeric(priceTierCount)) {
            priceTierCount = parseInt(priceTierCount)

            // set a list of priceTiers upto the count
            const newPriceTierList = []
            const defaultPriceTier = {
                lowLimit: 1,
                highLimit: '',
                fee: ''
            }
            newPriceTierList.push(defaultPriceTier)

            for (var iLoop = 1; iLoop < priceTierCount; iLoop++) {
                const newPriceTier = {
                    lowLimit: '--',
                    highLimit: '',
                    fee: ''
                }
                newPriceTierList.push(newPriceTier)
                // for the last element set highLimit = 999999
                if (iLoop === priceTierCount - 1) {
                    newPriceTierList[priceTierCount - 1].highLimit = 999999
                }
            }
            setPriceTierList(newPriceTierList)
        }
    }

    function highLimitChange(event, priceTierIndex) {
        const newPriceTierList = [...priceTierList]
        const highLimit = event.target.value

        if (isNumeric(highLimit)) {
            newPriceTierList[priceTierIndex].highLimit = highLimit
            if (priceTierIndex < newPriceTierList.length - 1) {
                // set the newLowLimit as pervHighLimit + 1
                newPriceTierList[priceTierIndex + 1].lowLimit =
                    parseInt(newPriceTierList[priceTierIndex].highLimit) + 1
                setPriceTierList(newPriceTierList)
            }
        }
    }

    function priceChange(event, priceTierIndex) {
        const newPriceTierList = [...priceTierList]
        const newFee = event.target.value
        if (isNumeric(newFee)) {
            newPriceTierList[priceTierIndex].fee = newFee
            setPriceTierList(newPriceTierList)
        }
    }

    function updateRateCardInfo({
        rateCardDescription,
        addFee,
        addFeeType,
        addFeeForEveryAmount,
        addFeeAfterAmount,
        addFeeMaxAmount
    }) {
        const newRateCardInfo = { ...rateCardInfo }
        if (rateCardDescription) {
            newRateCardInfo.rateCardDescription = rateCardDescription
        }

        if (addFee) {
            newRateCardInfo.addFee = addFee
        }

        if (addFeeType) {
            newRateCardInfo.addFeeType = addFeeType
        }

        if (addFeeForEveryAmount) {
            newRateCardInfo.addFeeForEveryAmount = addFeeForEveryAmount
        }

        if (addFeeAfterAmount) {
            newRateCardInfo.addFeeAfterAmount = addFeeAfterAmount
        }
        if (addFeeMaxAmount) {
            newRateCardInfo.addFeeMaxAmount = addFeeMaxAmount
        }
        setRateCardInfo(newRateCardInfo)
    }

    function handleInputChange(event) {
        switch (event.target.name) {
            case 'rateCardDescription':
                updateRateCardInfo({ rateCardDescription: event.target.value })
                break
            case 'addFee':
                updateRateCardInfo({ addFee: event.target.value })
                break
            case 'addFeeType':
                if (event.target.value === 'AMT') {
                    updateRateCardInfo({ addFeeType: event.target.value })
                } else if (event.target.value === 'PCT') {
                    updateRateCardInfo({
                        addFeeType: event.target.value,
                        addFeeForEveryAmount: '0'
                    })
                }
                break
            case 'addFeeForEveryAmount':
                updateRateCardInfo({ addFeeForEveryAmount: event.target.value })
                break
            case 'addFeeAfterAmount':
                updateRateCardInfo({ addFeeAfterAmount: event.target.value })
                break
            case 'addFeeMaxAmount':
                updateRateCardInfo({ addFeeMaxAmount: event.target.value })
                break
            default:
                break
        }
    }

    function saveRateCard() {
        setErrorMessages(null)
        const newRateCardInfo = { ...rateCardInfo }
        newRateCardInfo.priceTiers = priceTierList

        let newErrorMessages = validateCreateRateCard(newRateCardInfo)

        console.log('newErrorMessages', newErrorMessages)

        if (Object.keys(newErrorMessages).length > 0) {
            setErrorMessages(newErrorMessages)
            return false
        }

        saveRateCardData(newRateCardInfo)
            .then((response) => {
                onRateCardSelection(response, feeOverrideIndex, true)
            })
            .catch((error) => {
                setErrorMessages({ saveCard: error?.response?.data?.error })
                console.log(error)
            })
    }

    function displayErrorMessage(errorMessages) {
        console.log('errorMessages', errorMessages)
        if (errorMessages && errorMessages.saveCard) {
            if (errorMessages.saveCard === 'RATE_CARD_EXISTS') {
                return (
                    <Alert
                        onCloseClick={function Ua() {}}
                        title="Alert Title Example"
                        type="error">
                        <span>A rate card with this fees already exists</span>
                    </Alert>
                )
            } else {
                return (
                    <Alert
                        onCloseClick={function Ua() {}}
                        title="Alert Title Example"
                        type="error">
                        <span>There was an error when saving rate details</span>
                    </Alert>
                )
            }
        }
        return <></>
    }

    return (
        <>
            <Grid
                container
                columnSpacing={'15px'}
                sx={{ marginBlockEnd: '10px' }}
                data-testid={'container_CreateRateCard' + moduleid}>
                <Grid item xs={12}>
                    {errorMessages && displayErrorMessage(errorMessages)}
                </Grid>
                <Grid item xs={4}>
                    <TextInput
                        label="Number of tiers"
                        name="feeTierCount"
                        value={priceTierList.length}
                        onChange={priceTierCountChange}
                        required={true}
                    />
                </Grid>
                <Grid item xs={8}>
                    <TextInput
                        label="Description"
                        name="rateCardDescription"
                        value={rateCardInfo.rateCardDescription}
                        onChange={handleInputChange}
                        hasError={errorMessages?.rateCardDescription}
                        errorMessage={errorMessages?.rateCardDescription}
                    />
                </Grid>
            </Grid>
            <Box
                backgroundColor="#F4F4F4"
                component="div"
                padding="8px"
                width="100%"
                marginBottom="15px">
                <Grid container columnSpacing="15px">
                    <Grid item xs={4}>
                        From
                    </Grid>
                    <Grid item xs={4}>
                        To
                    </Grid>
                    <Grid item xs={4}>
                        Fee
                    </Grid>

                    {priceTierList &&
                        priceTierList.length > 0 &&
                        priceTierList.map((priceTier, index) => {
                            return (
                                <>
                                    <Grid item xs={4}>
                                        <TextInput
                                            value={priceTier.lowLimit}
                                            disabled
                                            inputPrefix={'$'}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextInput
                                            name="highLimitTxt"
                                            maxLength="6"
                                            className="rateCardAmtInput"
                                            value={priceTier.highLimit}
                                            inputPrefix={'$'}
                                            onBlur={(e) =>
                                                highLimitChange(e, index)
                                            }
                                            hasError={
                                                errorMessages?.priceTiers &&
                                                errorMessages?.priceTiers[index]
                                                    ?.highLimit
                                            }
                                            errorMessage={
                                                errorMessages?.priceTiers[index]
                                                    ?.highLimit
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextInput
                                            name="feeTxt"
                                            maxLength="5"
                                            className="rateCardAmtInput"
                                            inputPrefix={'$'}
                                            value={priceTier.fee}
                                            onBlur={(e) =>
                                                priceChange(e, index)
                                            }
                                            hasError={
                                                errorMessages?.priceTiers &&
                                                errorMessages?.priceTiers[index]
                                                    ?.fee
                                            }
                                            errorMessage={
                                                errorMessages?.priceTiers[index]
                                                    ?.fee
                                            }
                                        />
                                    </Grid>
                                </>
                            )
                        })}
                </Grid>
            </Box>

            {priceTierList && priceTierList.length > 0 && (
                <>
                    <Grid
                        container
                        columnSpacing={'15px'}
                        rowSpacing={'10px'}
                        sx={{ marginBlockEnd: '10px' }}>
                        <Grid item xs={12}>
                            <h3>Optional Adders</h3>
                        </Grid>
                        <Grid item xs={6}>
                            <TextInput
                                name="addFeeAfterAmount"
                                label="When The Sale Price Reaches"
                                maxLength="6"
                                onChange={handleInputChange}
                                defaultValue={rateCardInfo.addFeeAfterAmount}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <SelectInput
                                name="addFeeType"
                                label="Function"
                                options={dollarPercentOptions}
                                onChange={handleInputChange}
                                value={rateCardInfo.addFeeType}
                                displayDeselectOption={false}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextInput
                                name="addFee"
                                label="Amount"
                                maxLength="5"
                                onChange={handleInputChange}
                                value={rateCardInfo.addFee}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            {rateCardInfo &&
                                rateCardInfo.addFeeType === 'AMT' && (
                                    <TextInput
                                        name="addFeeForEveryAmount"
                                        label="For Each"
                                        maxLength="6"
                                        onChange={handleInputChange}
                                        defaultValue={
                                            rateCardInfo.addFeeForEveryAmount
                                        }
                                    />
                                )}
                        </Grid>

                        <Grid item xs={6}>
                            <TextInput
                                name="addFeeMaxAmount"
                                label="Maximum Charge Amount"
                                maxLength="6"
                                onChange={handleInputChange}
                                defaultValue={rateCardInfo.addFeeMaxAmount}
                            />
                        </Grid>
                    </Grid>

                    <SubmitCancelBox
                        onSubmit={saveRateCard}
                        onCancel={toggleRateCardCreateModal}
                    />
                </>
            )}
        </>
    )
}
