import React from 'react'
import { auctionDisplayWithCode } from '../../utils/auction.util'
import { DisplayFlatTieredDescription } from '../search/DisplayFlatTieredDescription'
import { DisplayFeeTierAmount } from '../search/DisplayFeeTierAmount'
import { renderDateFromIsoString } from '../../utils/Formatters'
import { CheckBox } from '@interstate/components/CheckBox'
import { Table } from '@interstate/components/Table'
import { useStaticDataContext } from '../../context/StaticDataContext'

export function ReviewFeesPageNewFeesTable({ tableData }) {
    const staticData = useStaticDataContext()
    function displayAuctionList(saleTypeFee) {
        let auctionNamesDisplay = saleTypeFee.auctionCodeList
            ?.map((auctionCode) => {
                return auctionDisplayWithCode(
                    auctionCode,
                    staticData.auctionInfoMap
                )
            })
            .join(', ')
        if (
            saleTypeFee.excludedAuctionList &&
            saleTypeFee.excludedAuctionList.length > 0
        ) {
            auctionNamesDisplay +=
                ' (Excluded: ' +
                saleTypeFee.excludedAuctionList.join(', ') +
                ')'
        }
        return auctionNamesDisplay
    }

    return (
        <Table
            columns={[
                {
                    className: 'column-contractType',
                    dataIndex: 'contractType',
                    render: (item) => <div>{item}</div>,
                    title: 'Fee Schedule Type'
                },
                {
                    className: 'column-auctionCode',
                    render: (saleTypeFee) => displayAuctionList(saleTypeFee),
                    title: 'Location'
                },
                {
                    className: 'column-saleType',
                    dataIndex: 'saleType',
                    title: 'Sale type'
                },
                {
                    className: 'column-customer',
                    dataIndex: 'customer',
                    title: 'Customer'
                },
                {
                    className: 'column-feeType',
                    dataIndex: 'feeCode',
                    title: 'Fee type'
                },
                {
                    className: 'column-flatTiered',
                    render: (item) => (
                        <div>
                            <DisplayFlatTieredDescription
                                feeScheduleFlat={
                                    item
                                }></DisplayFlatTieredDescription>
                        </div>
                    ),
                    title: 'Flat/Tiered'
                },
                {
                    className: 'column-Amount',
                    render: (item) => (
                        <div>
                            <DisplayFeeTierAmount
                                feeScheduleFlat={item}></DisplayFeeTierAmount>
                        </div>
                    ),
                    title: 'Amount/Tiers'
                },
                {
                    className: 'column-startDate',
                    dataIndex: 'startDate',
                    render: (item) => (
                        <div>{renderDateFromIsoString(item, 'MM/DD/YYYY')}</div>
                    ),
                    title: 'Start date'
                },
                {
                    className: 'column-endDate',
                    dataIndex: 'endDate',
                    render: (item) => (
                        <div>{renderDateFromIsoString(item, 'MM/DD/YYYY')}</div>
                    ),
                    title: 'End date'
                }
            ]}
            data={tableData}
            tableLayout={'auto'}
            enablePagination={true}
            id="newFeesTable"
            onChangePage={function Ua() {}}
            onChangePageSize={function Ua() {}}
            onChangeSelectedRows={function Ua() {}}
            onChangeSorter={function Ua() {}}
            onExpandClick={function Ua() {}}
            onRowCallback={function Ua() {}}
            onRowSelect={function Ua() {}}
        />
    )
}
