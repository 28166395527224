import { React, useEffect, useState } from 'react'
import {
    createSaleTypes,
    updateSaleTypes,
    useProcessConfigHistory
} from '../../service/configService'
import { Table } from '@interstate/components/Table'
import { PageHeader } from '../../components/PageHeader'
import { Box } from '@interstate/components/Box'
import { SubmitCancelBox } from '../../components/common/SubmitCancelBox'
import { PatTextInput } from '../../components/common/PatTextInput'
import { PatErrorAlert } from '../../components/common/PatErrorAlert'
import { PatWarningAlert } from '../../components/common/PatWarningAlert'
import { PatSelectInput } from '../../components/common/PatSelectInput'
import { DEFAULT_SALE_TYPE } from '../../utils/constants'

export function SaleTypesEditForm({
    saleTypes,
    edit,
    cancelEdit,
    saveEdit,
    doesSaleTypeExists
}) {
    const [name, setName] = useState(edit.name)
    const [parent, setParent] = useState(edit['parent_id'])
    const [defaultParent, setdefaultParent] = useState(null)
    const {
        value: history,
        loading: historyLoading,
        error: historyError
    } = useProcessConfigHistory(edit.id)
    const [error, setError] = useState(null)
    const [warning, setWarning] = useState(null)

    const hasChildren = (id) => {
        return saleTypes.find((item) => item.parent_id === id)
    }

    const onSave = () => {
        setError(null)

        if (doesSaleTypeExists(name)) {
            setError('Sale Type already exists')
            return
        }

        if (!name || name?.replace(' ', '').length === 0) {
            setError('Sale Type cannot be empty')
            return
        }

        const SaleTypeBO = {
            name: name,
            parent_id: parent
        }
        if (edit?.id === 'NEW') {
            createSaleTypes(SaleTypeBO)
                .then(() => {
                    saveEdit()
                })
                .catch((e) => {
                    console.log(e)
                    setError('Error with API creating saletype')
                })
        } else {
            updateSaleTypes(SaleTypeBO, edit.id)
                .then(() => {
                    saveEdit()
                })
                .catch((e) => {
                    console.log(e)
                    if (e?.response?.data?.error) {
                        setError(e.response.data.error)
                    } else {
                        setError('Error with API updating saletype')
                    }
                })
        }
    }

    useEffect(() => {
        let defaultParent = saleTypes.find(
            (item) => item.name === DEFAULT_SALE_TYPE
        )
        setdefaultParent(defaultParent.id)
        if (edit.id === 'NEW') {
            setParent(defaultParent?.id)
        }

        if (hasChildren(edit.id)) {
            setWarning('Cannot edit a parent Sale Type with children')
        }
    }, [saleTypes, edit.id])

    const options = saleTypes
        .filter(
            (item) =>
                item['parent_id'] === defaultParent ||
                item.name === DEFAULT_SALE_TYPE
        )
        .map((item) => {
            return {
                value: item.id,
                label: item.name
            }
        })
    console.log(edit)
    return (
        <div>
            <PatErrorAlert error={error} />
            <PatWarningAlert error={warning} />
            <PatTextInput
                required
                label="Sale Type"
                value={name}
                onChange={(e) => {
                    setName(e.target.value)
                }}
            />
            <PatSelectInput
                required
                label="Parent"
                options={options}
                value={parent}
                onChange={(e) => {
                    setParent(e.target.value)
                }}
                disabled={hasChildren(edit.id)}
            />
            <SubmitCancelBox onSubmit={onSave} onCancel={cancelEdit} />
            <PatErrorAlert error={historyError} text="ERROR loading history" />
            {!historyLoading && history?.length > 0 && (
                <Box marginTop="10px">
                    <PageHeader titleValue="History" />
                    <Table
                        sortableColumns={true}
                        displayFilter={true}
                        highlightOnHover={true}
                        headerBackgroundColor="dark"
                        columns={[
                            {
                                title: 'SaleType',
                                dataIndex: 'name'
                            },
                            {
                                title: 'Date',
                                dataIndex: 'updated'
                            },
                            {
                                title: 'Updated By',
                                dataIndex: 'updatedBy'
                            }
                        ]}
                        data={history}
                    />
                </Box>
            )}
        </div>
    )
}
