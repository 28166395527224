import {
    isEmptyArray,
    isEmptyString,
    isEmptyStringArray,
    nonEmptyErrors
} from '../../utils/commonValidations'

export function validateSearchFields({
    feeScheduleType,
    selectedLocations,
    feeType,
    saleType,
    startDate,
    endDate,
    description,
    accounts,
    groupCodes,
    altGroupCodes
}) {
    let errors = {}
    if (
        isEmptyString(feeScheduleType) &&
        isEmptyArray(selectedLocations) &&
        isEmptyString(feeType) &&
        isEmptyString(saleType) &&
        isEmptyString(startDate) &&
        isEmptyString(endDate) &&
        isEmptyString(description) &&
        isEmptyStringArray(accounts) &&
        isEmptyStringArray(groupCodes) &&
        isEmptyStringArray(altGroupCodes)
    ) {
        errors.searchText = 'Atleast one search field is required'
    }

    return nonEmptyErrors(errors)
}
